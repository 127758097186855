@import "../../styles/variables.module";
.articlesCard {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  @include large_device {
    max-width: 100%;
    margin: 0 auto;
  }
  [class*="arrow"] {
    @include medium_device {
      top: auto;
      bottom: -10px;
    }
  }
  [class*="prev"] {
    @include up_large {
      left: -30px;
    }
    @include extra_large {
      left: -10px;
    }
    @include medium_device {
      left: calc(50% - 35px);
    }
  }
  [class*="next"] {
    @include up_large {
      right: -30px;
    }
    @include extra_large {
      right: -10px;
    }
    @include medium_device {
      right: calc(50% - 35px);
    }
  }
}
.cardSkeletonWrap {
  margin-bottom: 70px;
  width: 100%;
  flex-flow: wrap;
  @include large_device {
    margin-bottom: 35px;
  }
  .cardSkeleton {
    align-items: center;
    margin-bottom: 20px;
    flex: auto;
    @include large_device {
      margin-left: 0;
    }
  }
}
.noReviewFound {
  margin-bottom: 10%;
}
